import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import VueCookies from 'vue-cookies';
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
import vSelect from 'vue-select';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
function generateRandomUniqueID() {
  let a = '';
  let b = '';
  let c = '';
  let d = '';
  const time = Math.round(new Date().getTime() / 1000);
  const version = window.navigator.appVersion;
  const { href } = window.location;

  // a - unix timestamp
  a = time.toString(16)
    .substring(0, 8);

  // b - browser
  const match = version.match(/\d+/g);
  if (!match) {
    // eslint-disable-next-line no-throw-literal
    throw 'Invalid browser version string';
  }
  let sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, ii = match.length; i < ii; ++i) {
    sum += parseInt(match[i], 10);
  }
  b = (sum * sum * sum).toString(16)
    .substring(0, 6);

  // c - url
  c = (href.length * href.length * href.length).toString(16)
    .substring(0, 4);

  // d - random
  d = Math.random()
    .toString()
    .substring(2);
  d = parseInt(d, 10);
  d = d.toString(16)
    .substring(0, 6);

  const generatedUUID = [a, b, c, d].join('-');
  document.body.setAttribute('clientID', generatedUUID);
  return generatedUUID;
}

Vue.use(new VueSocketIO({
  debug: true,
  connection: `${process.env.VUE_APP_API_URL}`,
  options: {
    query: {
      clientID: generateRandomUniqueID(),
    },
  },
}));

Vue.use(VueCookies);
Vue.use(LottieVuePlayer);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
