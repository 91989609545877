<template>
  <div class="header">
    <div class="back-btn" @click="dealWithBackButton()">
      <svg xmlns="http://www.w3.org/2000/svg" width="19.207" height="13.414"
           viewBox="0 0 19.207 13.414" v-if="this.$store.state.showBackButton">
        <g id="Group_817" data-name="Group 817" transform="translate(-13.793 -19.793)">
          <line id="Line_79" data-name="Line 79" x2="6" y2="6" transform="translate(14.5 26.5)"
                fill="none" stroke="#707070" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_84" data-name="Line 84" y1="6" x2="6" transform="translate(14.5 20.5)"
                fill="none" stroke="#707070" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_80" data-name="Line 80" x2="18" transform="translate(14.5 26.5)"
                fill="none" stroke="#707070" stroke-linecap="round" stroke-width="1"/>
        </g>
      </svg>
      <img @click="goToHome()" :src="require('../assets/migrosx.svg')" alt="">
    </div>
    <LanguageSwitcher></LanguageSwitcher>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default {
  name: 'Header',
  components: {
    LanguageSwitcher,
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //----------------------------------
    // Event Handlers
    //----------------------------------
    dealWithBackButton() {
      if (this.$store.state.areRulesActive && !this.$store.state.typeSelectionActive) {
        console.log('1');
        this.closeRules();
      } else if (this.$store.state.typeSelectionActive) {
        console.log('2');
        this.$store.state.typeSelectionActive = false;
        this.$store.state.selectedType = '';
        this.$store.state.showBackButton = false;
      } else {
        console.log('3');
        this.editorToMessage();
      }
    },
    goToHome() {
      this.$router.push({ name: 'Home' });
      this.$store.state.showBackButton = false;
    },
    editorToMessage() {
      this.$router.push({ name: 'Home' });
      this.$store.state.showBackButton = false;
    },
    closeRules() {
      this.$store.state.areRulesActive = false;
      this.$store.state.showBackButton = false;
      document.body.classList.remove('no-scroll');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 54px;
  padding: 7px 17px;
  border-bottom: 1px solid #333;
  display: flex;
  position: fixed;
  width: calc(100vw - 34px);
  z-index: 50000;
  background: white;
  justify-content: space-between;
  align-items: center;

  img {
    max-width: 98px;
  }

  .back-btn {
    transition: all 0.3s ease-in;
    will-change: contents;

    svg {
      margin-right: 15px;
    }
  }
}
</style>
