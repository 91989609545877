<template>
  <div class="rules" :class="{active: 'active'}">
    <h1 class="w-bar">{{$t('rules_title')}}</h1>
    <p v-html="$t('rules_content')"></p>
  </div>
</template>

<script>
export default {
  name: 'Rules',
  components: {},
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    active: Boolean,
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>

<style lang="scss" scoped>
div.rules {
  background: white;
  text-align: left;
  position: fixed;
  padding: 0 15px;
  top: 55px;
  left: 0;
  height: calc(100vh - 54px);
  z-index: 20000;
  overflow-y: scroll;

  h1 {
    position: relative;
    color: #333;
    text-align: left;
    font-size: 26px;

    &.w-bar {
      margin: 0;

      &:before {
        content: '';
        display: block;
        width: 50px;
        height: 7px;
        margin-top: 46px;
        margin-bottom: 20px;
        background: #FF6600;
      }
    }
  }

  p {
    padding-bottom: 5rem;
  }
}
</style>
