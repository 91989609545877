import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const supportedLocales = {
  fr: 'Francais',
  de: 'Deutsch',
  it: 'Italiano',
};

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys()
    .forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
  return messages;
}

function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales).includes(locale);
}

function getBrowserLocale(options) {
  const defaultOptions = { countryCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim()
      .split(/-|_/)[0]
    : navigatorLocale.trim();

  return trimmedLocale;
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  console.log(browserLocale);

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  }

  return process.env.VUE_APP_I18N_LOCALE || 'de';
}

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: loadLocaleMessages(),
});
