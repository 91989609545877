<template>
  <div class="language-selector__container">
    <v-select v-model="selected" :options="options" :searchable="false" :appendToBody="false"
              :filterable="false" :clearable="false"></v-select>
    <!--    <select class="js_language_selector" @change="onChangeMethod($event)">
          <option value="de">DE</option>
          <option value="fr">FR</option>
        </select>-->
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';

export default {
  name: 'LanguageSwitcher',
  components: {},
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      selected: this.$i18n.locale,
      options: [
        'de', 'fr', 'it',
      ],
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {
    selected(newValue) {
      console.log(newValue);
      this.$i18n.locale = newValue.toLowerCase();
    },
  },
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vs__search {
  // display: none;
}

::v-deep .vs__dropdown-toggle {
  border: 0 !important;
}

::v-deep .vs__search {
  display: none;
}

::v-deep div.vs-open {
  .vs__search {
    display: block;
  }
}

::v-deep .vs--single.vs--open .vs__selected {
  top: -7px;
}

.language-selector__container {
  position: relative;
  width: 60px;
  font-size: 16px;
  color: #7F7F7F;
  text-transform: uppercase;
}

</style>
