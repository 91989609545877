import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isApplicationEnabled: false,
    messageDisplayTime: 0,
    resendLockTime: 0,
    resendLock: false,
    messageSentTimestamp: 0,
    message: '',
    messageFormat: null,
    areRulesActive: false,
    typeSelectionActive: false,
    selectedType: '',
    showBackButton: false,
    imagePath: '',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
