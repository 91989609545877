<template>
  <footer class="footer">
    <hr>
    <ul class="nav">
      <li v-for="(navItem, i) in $t('footerNavigation')" :key="`footerNav-${i}`">
        <a :href="navItem.url" :target="navItem.target" class="">
          {{navItem.label}}
        </a>
      </li>
    </ul>
    <div class="cright">
      {{$t('footerCopyright', {year: new Date().getFullYear()})}}
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding: 37px 15px 0 15px;

  ul {
    padding-left: 0;
    list-style-type: none;

    li {
      text-align: left;
      padding: 0 0 8px 0;
      margin-top: 6px;

      a {
        color: #646464;
        text-decoration: none;
      }
    }
  }

  .cright {
    background: #FF6600;
    color: #fff;
    padding: 16px 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}
</style>
