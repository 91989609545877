<template>
  <div>
    <router-link class="btn primary" :class="{disabled: isDisabled}" :to="url" v-if="url">{{
        label
      }}
    </router-link>
    <a class="btn primary" :class="{disabled: isDisabled}" v-if="!url">{{ label }}</a>
  </div>
</template>

<script>
export default {
  name: 'MButton',
  components: {},
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    label: String,
    url: String,
    state: Boolean,
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {
    isDisabled() {
      return this.state;
    },
  },
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>

<style lang="scss">
div.btn.primary,
label.btn.primary,
a.btn.primary {
  background: #FF6600;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  text-decoration: none;
  font-size: 20px;
  margin: 0;
  border-radius: 4px;
  pointer-events: all;

  &.disabled {
    background: #ccc;
    pointer-events: none;
  }
}
</style>
