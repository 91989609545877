<template>
  <section class="message">
    <h1>{{ $t('message_title') }}</h1>
    <form action="" onsubmit="return false;">
      <div class="form-row">
        <label for="messageInp">{{ $t('message_info') }}</label>
        <input type="text" name="messageInp" id="messageInp" placeholder="i <3 Migros"
               maxlength="25"
               @keyup.enter="checkProfanity"
               :class="{hasError: hasError}"
               v-model="messageText" @input="updateStoreMessage()">
        <p class="error-text" v-if="hasError">{{ $t('message_hasError') }}</p>
      </div>
      <div class="form-row">
<!--        <div class="agb">
          <label class="switch">
            <input type="checkbox" v-model="agbAccepted">
            <span class="slider round"></span>
          </label>
          <p v-html="$t('message_agb')"></p>
        </div>
        <Rules v-if="this.$store.state.areRulesActive"></Rules>-->
        <MButton :label="$t('btn_next')"
                 :state="disabledState"
                 @click.native="checkProfanity" style="margin-top: 8px;"></MButton>
      </div>
    </form>
  </section>
</template>

<script>
/* import Rules from '@/components/Rules.vue'; */
import MButton from '@/components/MButton.vue';
import axios from 'axios';
/* import Rules from '@/components/Rules.vue'; */

export default {
  name: 'Message',
  components: {
    MButton,
    /* Rules, */
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    imageUploadEnabled: {
      type: Boolean,
      default: false,
    },
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      hasError: false,
      messageText: '',
      agbAccepted: true,
      isRulesActive: false,
      disabledState: true,
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append('msg', this.messageText.toLowerCase());
      return params;
    },
  },
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {
    messageText(newVal) {
      if (newVal.length <= 3) {
        this.disabledState = true;
      } else {
        this.disabledState = false;
      }
    },
  },
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  mounted() {
  },
  // beforeUpdate() {},
  updated() {
  },
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    isValidURL(str) {
      const pattern = new RegExp('^(https?:\\/\\/)?'
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
        + '((\\d{1,3}\\.){3}\\d{1,3}))'
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
        + '(\\?[;&a-z\\d%_.~+=-]*)?'
        + '(\\#[-a-z\\d_]*)?$', 'i');
      return !!pattern.test(str);
    },
    isNumberFree(str) {
      const pattern = new RegExp('^([0][1-9][0-9](\\s|)[0-9][0-9][0-9](\\s|)[0-9][0-9](\\s|)[0-9][0-9])$|^(([0][0]|\\+)[1-9][0-9](\\s|)[0-9][0-9](\\s|)[0-9][0-9][0-9](\\s|)[0-9][0-9](\\s|)[0-9][0-9])$');
      return !!pattern.test(str);
    },
    //----------------------------------
    // Event Handlers
    //----------------------------------
    checkProfanity() {
      if (this.disabledState) {
        return;
      }
      this.$emit('overlaynow', {
        enabled: true,
        type: 'text',
      });

      // eslint-disable-next-line max-len
      if (!this.isValidURL(this.messageText) && !this.isNumberFree(this.messageText)) {
        axios.get(`${process.env.VUE_APP_API_URL}/purify/checkMessage`, {
          params: this.axiosParams,
        })
          .then((response) => {
            console.log('response');
            console.log(response);

            if (response.data.swearJar === 'true' || response.data.openAI === 'true') {
              this.$emit('overlaynow', {
                enabled: false,
                type: 'text',
              });
              this.hasError = true;
            } else {
              this.$emit('overlaynow', {
                enabled: false,
                type: 'text',
              });
              this.$router.push({
                name: 'message-editor',
                params: {
                  message: this.messageText,
                },
              });
            }
          });
      } else {
        this.hasError = true;
        this.$emit('overlaynow', {
          enabled: false,
          type: 'text',
        });
      }
    },
    updateStoreMessage() {
      this.messageText = this.messageText.replace(/['"']+/g, '');
      this.$store.state.message = this.messageText;
      if (this.hasError) {
        this.hasError = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-text {
  color: #FF0000;
}

section {
  padding: 15px 0;
}

h1 {
  text-align: left;
  font-size: 26px;
  margin: 0 0 20px 0;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
}

.form-row {
  text-align: left;

  label {
    display: block;
  }

  input {
    margin-top: 8px;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    padding: 15px;
    font-size: 20px;
    text-transform: uppercase;
    width: calc(100% - 30px);
    color: #000;

    &.hasError {
      color: #ff0000;
      border-color: #ff0000;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 60px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #FF6600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #FF6600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.agb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 20px;

  p a {
    color: #333;
  }

  ::v-deep span {
    color: #333;
    text-decoration: underline;
    cursor: pointer;
  }
}

a.btn.primary {
  background: #FF6600;
  color: #fff;
  display: block;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  text-decoration: none;
  font-size: 20px;
  margin: 0 0;
  border-radius: 4px;
  pointer-events: all;
  touch-action: auto;

  &.disabled {
    background: #ccc;
    pointer-events: none;
    touch-action: none;
  }
}
</style>
