<template>
  <div id="app">
    <Header></Header>
    <router-view class="content" v-if="whichDeviceType()"/>
    <NoDesktop v-if="!whichDeviceType()" :pImage="placeholderImageDesktop"></NoDesktop>
    <Footer v-if="whichDeviceType()"></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import NoDesktop from '@/components/NoDesktop.vue';
import axios from 'axios';
import dayjs from 'dayjs';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    NoDesktop,
  },
  data() {
    return {
      dayTime: 0,
      nighTime: 0,
      isDay: null,
      applicationEnabled: false,
      placeholderImageDesktop: '',
    };
  },
  methods: {
    whichDeviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return true;
      }

      if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}/settings`)
      .then((response) => {
        console.log(response);
        this.applicationEnabled = (response.data.data[0].metavalue !== '0');
        if (response.data.data[11].metavalue !== '') {
          this.placeholderImageDesktop = `${process.env.VUE_APP_API_URL}/images/${response.data.data[11].metavalue}`;
        } else {
          this.placeholderImageDesktop = '';
        }

        this.$store.state.isApplicationEnabled = (response.data.data[0].metavalue !== '0');

        this.dayTime = response.data.data[4].metavalue.replace('.', ':');
        this.nighTime = response.data.data[5].metavalue.replace('.', ':');
        const tempDate = new Date();
        const dayjsObjDayTime = dayjs(new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), this.dayTime.split(':')[0], this.dayTime.split(':')[1]));
        const dayjsObjNightTime = dayjs(new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), this.nighTime.split(':')[0], this.nighTime.split(':')[1]));
        this.isDay = dayjs()
          .isBetween(dayjsObjDayTime, dayjsObjNightTime);
        document.body.setAttribute('data-daymode', this.isDay);
      });
  },
};
</script>
<style lang="scss">

:root {
  --vs-dropdown-option--active-bg: #7F7F7F;
  --vs-dropdown-option--active-color: #ccc;
  --vs-selected-color: #7F7F7F;
}

@font-face {
  font-family: 'Helvetica Now Text';
  src: url('./assets/fonts/HelveticaNowText-Regular.woff2') format('woff2'),
  url('./assets/fonts/HelveticaNowText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Text';
  src: url('./assets/fonts/HelveticaNowText-Bold.woff2') format('woff2'),
  url('./assets/fonts/HelveticaNowText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bourton Base';
  src: url('./assets/fonts/BourtonBase.woff2') format('woff2'),
  url('./assets/fonts/BourtonBase.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body, #app {
  height: 100%;
  margin: 0;
  font-family: 'Helvetica Now Text';
}

body, #app {
  display: flex;
  flex-direction: column;
  font-family: 'Helvetica Now Text';
}

.content {
  margin-top: 70px;
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

.no-scroll {
  overflow: hidden;
}
</style>
