import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Store from '../store/index';

Vue.use(VueRouter);
Vue.use(Store);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/message-editor',
    name: 'message-editor',
    component: () => import(/* webpackChunkName: "about" */ '../views/Editor.vue'),
  },
  {
    path: '/image-editor',
    name: 'image-editor',
    component: () => import(/* webpackChunkName: "about" */ '../views/Editor-Image.vue'),
  },
  {
    path: '/image-cropper',
    name: 'image-cropper',
    component: () => import(/* webpackChunkName: "about" */ '../views/ImageCropper.vue'),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "about" */ '../views/Result.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (!router.app.$cookies.get('MFestMessageDisplayed')) {
    if (to.name !== 'Home' && !router.app.$store.state.isApplicationEnabled) {
      next({
        name: 'Home',
        replace: true,
      });
    } else if (router.app.$store.state.isApplicationEnabled) {
      next();
    } else {
      next();
    }
  } else if (to.name !== 'result') {
    next({
      name: 'result',
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
