<template>
  <section class="message image">
    <h1 v-if="!minimal">{{ $t('messageimage_title') }}</h1>
    <form action="">
      <div class="form-row">
        <label for="messageInp" v-if="!minimal">{{ $t('image_info') }}</label>
        <label for="messageInp" class="btn primary" :class="{'disabled': buttonDisabled}" v-else>
          {{ $t('btn_uploadImage') }}
        </label>
        <input type="file" name="messageInp" id="messageInp"
               accept="image/x-png, image/jpeg"
               class="custom-file-input"
               :class="{hasError: hasError}"
               @change="imageUploaded">
        <p class="error-text" v-if="hasError">{{ $t('image_hasError') }}</p>
      </div>
      <div class="form-row">
        <!--        <MButton :label="$t('btn_next')"
                         :state="!this.selectedFile"
                         @click.native="checkImageProfanity"></MButton>-->
      </div>
    </form>
  </section>
</template>

<script>
/* import MButton from '@/components/MButton.vue'; */
import axios from 'axios';

export default {
  name: 'ImageUploader',
  components: {
    /* MButton, */
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    minimal: {
      type: Boolean,
      default: true,
    },
    buttonDisabled: {
      type: Boolean,
      default: true,
    },
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      hasError: false,
      selectedFile: null,
      pathToImage: null,
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {
    currentHost() {
      return process.env.VUE_APP_API_URL;
    },
  },
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  mounted() {
  },
  // beforeUpdate() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    checkImageProfanity() {
      this.$emit('overlaynow', {
        enabled: true,
        type: 'image',
      });

      console.log(`${process.env.VUE_APP_API_URL}${this.pathToImage}`);

      axios.get(`${process.env.VUE_APP_API_URL}/purify/checkImage`, {
        params: { imageURL: `${process.env.VUE_APP_API_URL}${this.pathToImage}` },
        /* params: { imageURL: 'https://api.migros-festival-message.ch/userContent/1685879635806.png' }, */
      })
        .then((response) => {
          console.log('response');
          console.log(response.data.summary);
          console.log('...........');
          console.log(response.data.summary.action === 'reject');
          console.log('...........');
          if (response.data.summary.action === 'reject') {
            // console.log('This would be correct');
            this.$emit('overlaynow', {
              enabled: false,
              type: 'image',
            });
            this.hasError = true; // CHANGE THIS BACK TO TRUE WHEN GOING LIVE

            // REMOVE THIS WHEN GOING LIVE
            /* this.$router.push({
              name: 'image-cropper',
              params: {
                message: 'https://api.migros-festival-message.ch/userContent/1685879635806.png',
              },
            }); */
          } else {
            // console.log('you should not be here');
            this.$emit('overlaynow', {
              enabled: false,
              type: 'image',
            });
            this.$router.push({
              name: 'image-cropper',
              params: {
                message: `${process.env.VUE_APP_API_URL}/${this.$store.state.imagePath}`,
              },
            });
          }
        });
    },
    imageUploaded() {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = document.getElementById('messageInp').files[0];
      console.log('images was set');
      console.log(this.selectedFile);

      const formData = new FormData();
      formData.append('file', this.selectedFile);

      axios.post(`${process.env.VUE_APP_API_URL}/purify/uploadImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          console.log('response');
          console.log(response.data.path);
          this.pathToImage = response.data.path;
          this.$store.state.imagePath = this.pathToImage;
          this.checkImageProfanity();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-text {
  color: #FF0000;
}

section {
  padding: 0 !important;
}

h1 {
  text-align: left;
  font-size: 26px;
  margin: 0 0 20px 0;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
}

.form-row {
  text-align: left;

  label {
    display: block;
  }

  input {
    margin-top: 8px;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    padding: 15px;
    font-size: 20px;
    text-transform: uppercase;
    width: calc(100% - 30px);
    color: #000;

    &.hasError {
      color: #ff0000;
      border-color: #ff0000;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 60px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #FF6600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #FF6600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.agb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 20px;

  p a {
    color: #333;
  }

  ::v-deep span {
    color: #333;
    text-decoration: underline;
    cursor: pointer;
  }
}

a.btn.primary {
  background: #FF6600;
  color: #fff;
  display: block;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  text-decoration: none;
  font-size: 20px;
  margin: 0 0;
  border-radius: 4px;
  pointer-events: all;
  touch-action: auto;

  &.disabled {
    background: #ccc;
    pointer-events: none;
    touch-action: none;
  }
}

input[type="file"] {
  display: none;
}

label {
  text-align: center;
  font-weight: 600;
}
</style>
