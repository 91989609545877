<template>
  <div class="no-desktop-container">
    <div>
      <img :src="pImage" alt="" v-if="pImage !== ''">
<!--      <img :src="require('../assets/square.jpg')" alt="" v-if="pImage === ''">-->
    </div>
    <div>
      <h1 class="w-bar">{{$t('noDesktop_title')}}</h1>
      <p v-html="$t('noDesktop_content')"></p>
      <img :src="require('../assets/qrcode.svg')" alt="" class="img-fluid">
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoDesktop',
  components: {},
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    pImage: {
      type: String,
      default: '',
    },
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>

<style lang="scss" scoped>
.no-desktop-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 93%;
  width: 100%;
  padding-top: 63px;

  .w-bar {
    margin: 0;
    margin-top: 94px;

    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 7px;
      margin-top: 46px;
      margin-bottom: 20px;
      background: #FF6600;
    }
  }

  > div {
    width: 50%;
    height: 100%;

    &:last-of-type {
      text-align: left;
      padding-left: 30px;
      padding-top: 0px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      &.img-fluid {
        max-width: 100%;
        -o-object-fit: none;
        object-fit: unset;
        height: auto;
        display: block;
        width: 141px;;
      }
    }
  }
}
</style>
